
* {
  margin: 0px; 
  padding: 0px;
  box-sizing: border-box;
  
  
}

html,body{
  margin:10px;
  padding: 10px;
}

h2 {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1rem;
  color: skyblue;
}

.autInp {
  width:100%;
}

/* img {
  height: 12.8rem;
  width: 12.8rem;
  position: absolute;
  top: 15%;
  left: 30%; 
  border-top-right-radius: 1rem; 
  border-bottom-left-radius: 0.5rem;
}  */

 
.doctor-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0rem !important;
  margin-top: 1.6rem;
  font-size: 12px !important;

} 

.PreForm {
  /* width:100%;
  height: 100%; */
  /* position: fixed; */
  background-color: #ee5522;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='137' height='137' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23d23d09' fill-opacity='0.82'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.82'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}


@media screen and (max-width : 918px){
  .prescription-view {
    margin:0px !important;
}
  
  .profession {
    /* margin-top:20px; */
    margin-bottom:40px;
  }
  
  .aakar-logo {
    left:80px;
    margin-bottom: 20px;
  }

} 
.doctor-info span {
  font-size: 2rem;
  font-weight: 700;
  color: #5c8ab8;
  margin-bottom: 1rem;
}
@media screen and (max-width:1300px) {
  .content {
    padding: 10px 10px !important;
  }  
}
   
num {
  color: #555;
  font-weight: 600;
}

.profession span {
  text-decoration: underline #5c8ab8;
}


.react-draggable .react-draggable-dragged{
  height:60px !important;
  padding: 0px ;
  margin: 0px;
}
@media only screen and (max-width:510px) {
  .prescription-form ,.prescription-view,.content{
      padding: 0px !important;
      
  } 
  .content{
    margin: 0px !important;
    
  }
  .description{
    padding: 0px !important;
    margin: 0px !important  ;
  }
  .aakar-logo{
    width: 200px !important  ;
  }
  /* body{
    padding: 0px !important;
  } */
  * {
    margin: 0px; 
    padding: 0px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width:950px) {
  .prescription-form ,.prescription-view{
      padding: 5px !important;
      
  } 
  .content{
    margin: 0px !important;
  }
  .aakar-logo{
    width: 360px;
  }
  /* body{
    padding: 0px !important;
  } */
}

@import "sheets-of-paper.css";

.page {
    border : 2px solid red;
     
	/* Styles for better appearance on screens only -- are reset to defaults in print styles later */

	/* Reflect the paper width in the screen rendering (must match size from @page rule) */
	width: 21cm;
	/* Reflect the paper height in the screen rendering (must match size from @page rule) */
	min-height: 29.7cm;

	/* Reflect the actual page margin/padding on paper in the screen rendering (must match margin from @page rule) */
	padding-left: 2cm;
	padding-top: 2cm;
	padding-right: 2cm;
	padding-bottom: 2cm;
}
/* Use CSS Paged Media to switch from continuous documents to sheet-like documents with separate pages */
@page {
	/* You can only change the size, margins, orphans, widows and page breaks here */

	/* Paper size and page orientation */
	size: A4 portrait;

	/* Margin per single side of the page */
	margin-left: 2cm;
	margin-top: 2cm;
	margin-right: 2cm;
	margin-bottom: 2cm;
}
.homebg{
  display: flex;
  flex-direction: row;
  align-items: center ;
  justify-content: center;
  padding: 40px;
}